<template>
	<h1>Page Not Found</h1>
	<p id="not-found-message">The page that you requested does not exist.</p>
</template>

<script>
	export default {
		name: 'Home'
	}
</script>

<style scoped lang="scss">
	#not-found-message {
		text-align:center;
	}
</style>